import { makeStyles, Theme } from '@material-ui/core/styles'
import { FONTS, COLORS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.whiteStain,
    width: '100%',
    height: 70,
    fontFamily: FONTS.Graphik.GraphikRegular,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
    '& span': {
      fontFamily: FONTS.Graphik.GraphikRegular,
    },
  },
  item: {
    backgroundColor: COLORS.whiteStain,
    paddingTop: 20,
  },
  image: {
    width: 13,
    height: 15,
  },
}))
