import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Layout from '../layouts/defaultLayout'
import TabPanel from '../components/tabs'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { styles } from '../pagesStyles/companyStyles'
import ContactUsTab from '../components/contactUsTab'
import TermsTab from '../components/termsTab'
import PrivacyTab from '../components/privacyTab'
import { LocationProps } from '@reach/router'
import SEO from '../components/seo'
import ContentContainer from './../components/contentContainer'
import ContactUsDropdown from '../components/contactUsDropdown'

interface CompanyPageProps {
  t: TFunction
  location: LocationProps
}

const tabsNames = {
  privacy: 'privacy',
  terms: 'terms',
  contactUs: 'contact-us',
  accountDeletion: 'account-deletion',
}

const getPageUrl = (hashLocation: string) => {
  return hashLocation.split('#')[1]
}
const CompanyPage = ({ t, location }: CompanyPageProps) => {
  const classes = styles()

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    // check hash value in page url
    const pageUrl = getPageUrl(location.hash)
    if (pageUrl === tabsNames.privacy || pageUrl === tabsNames.accountDeletion)
      setTabValue(2)
    else if (pageUrl === tabsNames.terms) setTabValue(1)
    else setTabValue(0)

    // scroll page up when navigating
    window.scrollTo(0, 0)
  }, [location])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const isAccountDeletion =
    getPageUrl(location.hash) === tabsNames.accountDeletion
  return (
    <Layout>
      <SEO
        title={t('company.metaTitle')}
        description={t('company.metaDescription')}
      />
      <section className={classes.tabsSection}>
        <ContentContainer>
          <ContactUsDropdown switchTab={handleChange} />
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleChange}
            aria-label="company tabs"
            variant="fullWidth"
            // indicatorColor="transparent"
          >
            <Tab
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              label={t('company.contactUs')}
            />
            <Tab
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              label={t('company.terms')}
            />
            <Tab
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              label={t('company.privacy')}
            />
          </Tabs>
        </ContentContainer>
        <ContentContainer>
          <TabPanel value={tabValue} index={0}>
            <ContactUsTab />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <TermsTab />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <PrivacyTab isAccountDeletion={isAccountDeletion} />
          </TabPanel>
        </ContentContainer>
      </section>
    </Layout>
  )
}

export default withTranslation()(CompanyPage)
