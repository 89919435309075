import React from 'react'

interface TabPanelProps {
  children: React.ReactNode
  index: any
  value: any
}

export default function CHRTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ position: 'relative' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </div>
  )
}
