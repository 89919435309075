import React, { useState, useEffect } from 'react'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { styles } from '../../pagesStyles/companyStyles'
import { ENDPOINTS } from '../../constants'
import axios from '../../axios'
import Spinner from '../spinner'
import { Typography } from '@material-ui/core'
interface PrivacyTabProps {
  t: TFunction
  isAccountDeletion: boolean
}

const PrivacyTab = ({ t, isAccountDeletion }: PrivacyTabProps) => {
  const classes = styles()
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const TAB = 'privacy'
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.cms.replace(':name', TAB))

        setLoading(false)
        setContent(data.content)
      } catch (err) {
        setLoading(false)
        setError(t('messages.somethingWentWrong'))
      }
    })()
  }, [])

  return loading ? (
    <Spinner />
  ) : error ? (
    <div className={classes.section}>
      <Typography variant="h1" component="h6">
        {error}
      </Typography>
    </div>
  ) : (
    <section
      dangerouslySetInnerHTML={{ __html: content }}
      className={classes.section}
    ></section>
  )
}

export default withTranslation()(PrivacyTab)
