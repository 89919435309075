import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../constants'
import arrow from '../assets/images/arrow.png'

export const styles = makeStyles(theme => ({
  selectMenu: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    letterSpacing: 1.2,
    display: 'block',
    width: '100%',
    backgroundColor: COLORS.whiteStain,
    border: 'none',
    padding: 20,
    fontSize: '0.75rem',
    color: COLORS.lightGrey,
    background: `url(${arrow}) no-repeat right ${COLORS.gainsboro}`,
    '-webkitAppearance': 'none',
    backgroundPositionX: '98%',
    backgroundSize: '15px',
  },
  optionSelected: {
    color: theme.palette.common.black,
  },
  option: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
  },
  placeholderOption: {
    color: COLORS.lightGrey,
    fontSize: '0.75rem',
  },
  root: {
    opacity: 1,
    overflow: 'initial',
    fontFamily: FONTS.Graphik.GraphikMedium,
    textTransform: 'lowercase',
    color: COLORS.ashGrey,
    backgroundColor: COLORS.whiteStain,
    border: 'none',
    fontSize: 12,
    letterSpacing: 1.2,
  },
  selected: ({
    selectedBgColor = theme.palette.common.black,
  }: {
    selectedBgColor?: string
  }) => ({
    backgroundColor: selectedBgColor,
    color: theme.palette.common.white,
    border: 'none',
    '& + $root': {
      zIndex: 1,
    },
    '& + $root:before': {
      opacity: 0,
    },
  }),
  section: {
    width: '100%',
    maxWidth: 700,
    margin: 'auto',
    marginTop: 80,
    marginBottom: 80,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      margin: '40px auto 40px auto',
    },
    '& p': {
      '& span': {
        letterSpacing: 1.2,
        lineHeight: 1.75,
      },
    },
  },
  lightButton: {
    border: 'solid',
    borderWidth: 2,
    width: '100%',
    maxWidth: 460,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 54,
    cursor: 'pointer',
    marginBottom: 120,
    fontFamily: FONTS.Graphik.GraphikMedium,
  },
  textArea: {
    display: 'block',
    width: '100%',
    backgroundColor: COLORS.whiteStain,
    border: 'none',
    padding: 20,
    letterSpacing: 1.2,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.75rem',
    '&::placeholder': {
      color: COLORS.lightGrey,
    },
    resize: 'none',
  },
  tabsSection: {
    marginTop: 90,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginTop: 40,
    },
  },
  inputWrapper: {
    marginBottom: 25,
    position: 'relative',
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: 15,
    marginTop: 30,
  },
  tabs: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  errorMsg: {
    color: COLORS.dangerRed,
    position: 'absolute',
    left: 0,
    top: -20,
    fontSize: 14,
    margin: 0,
  },
  successMsg: {
    color: COLORS.successGreen,
    fontSize: '0.875rem',
    margin: 0,
    textAlign: 'center',
  },
  firstInput: {
    marginTop: 25,
  },
  buttonContainer: {
    position: 'relative',
    margin: '0 auto',
  },
}))
