import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { withTranslation } from 'react-i18next'
import { styles } from './styles'
import { TFunction } from 'i18next'
import arrow from '../../assets/images/arrow.png'

interface DropdownProps {
  switchTab: Function
  t: TFunction
}

function ContactUsDropdown({ t, switchTab }: DropdownProps) {
  const classes = styles()

  const [open, setOpen] = React.useState(false)
  const [name, setName] = React.useState('contact us')

  const handleClick = () => {
    setOpen(!open)
  }

  const handleListItemClick = (event, index) => {
    setName(event.currentTarget.children[0].innerText)
    setOpen(!open)
    switchTab(event, index)
  }

  return (
    <>
      <List className={classes.root}>
        <ListItem button onClick={handleClick}>
          <ListItemText>{name}</ListItemText>
          <img
            src={arrow}
            className={classes.image}
            alt={t('imageAlts.downArrow')}
          />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={e => handleListItemClick(e, 0)}
              className={classes.item}
            >
              <ListItemText> {t('company.contactUs')}</ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={e => handleListItemClick(e, 1)}
              className={classes.item}
            >
              <ListItemText> {t('company.terms')}</ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={e => handleListItemClick(e, 2)}
              className={classes.item}
            >
              <ListItemText> {t('company.privacy')}</ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  )
}

export default withTranslation()(ContactUsDropdown)
