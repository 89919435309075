import { Typography } from '@material-ui/core'
import classnames from 'classnames'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { contactUsValidation, getErrorMsg } from '../../../utils/validation'
import axios from '../../axios'
import { ENDPOINTS, FONTS } from '../../constants'
import { styles } from '../../pagesStyles/companyStyles'
import Input from '../input'
import OutlinedButton from './../outlinedButton'

interface ContactUsTabProps {
  t: TFunction
}

const ContactUsTab = ({ t }: ContactUsTabProps) => {
  const classes = styles()
  const [optionSelected, setOptionSelected] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [fullNameErrorMsg, setFullNameErrorMsg] = React.useState('')
  const [emailErrorMsg, setEmailErrorMsg] = React.useState('')
  const [typeErrorMsg, setTypeErrorMsg] = React.useState('')
  const [messageErrorMsg, setMessageErrorMsg] = React.useState('')
  const [failErrorMsg, setFailErrorMsg] = React.useState('')
  const [successMsg, setSuccessMsg] = React.useState('')

  const selectClasses = classnames({
    [classes.selectMenu]: true,
    [classes.optionSelected]: optionSelected !== '',
  })

  const handleSubmitButtonClick = async event => {
    try {
      const isFormValid = await contactUsValidation({
        fullName,
        email,
        type: optionSelected,
        message,
      })

      if (isFormValid) {
        const contactUsInfo = {
          email,
          name: fullName,
          type: optionSelected,
          body: message,
        }

        await axios.post(ENDPOINTS.contactUs, contactUsInfo)

        setSuccessMsg('successMsg')
      }
    } catch (error) {
      const emailError =
        error?.response?.data?.message === '"email" must be a valid email'
          ? 'invalidEmail'
          : getErrorMsg(error, 'email')
      const nameError = getErrorMsg(error, 'fullName')
      const typeErrorMsg = getErrorMsg(error, 'type')
      const messageError = getErrorMsg(error, 'message')

      if (emailError || nameError || typeErrorMsg || messageError) {
        setEmailErrorMsg(emailError)
        setFullNameErrorMsg(nameError)
        setTypeErrorMsg(typeErrorMsg)
        setMessageErrorMsg(messageError)
      } else {
        setFailErrorMsg(t('messages.somethingWentWrong'))
      }
    }
  }

  const clearValidationMessages = () => {
    setSuccessMsg('')
    setFullNameErrorMsg('')
    setEmailErrorMsg('')
    setTypeErrorMsg('')
    setMessageErrorMsg('')
  }

  return (
    <section className={classes.section}>
      <Typography variant="h4" component="h1">
        {t('company.contactUs')}
      </Typography>
      <Typography variant="subtitle1" component="p">
        {t('company.ifYouHaveQuestions')}
      </Typography>
      <Typography variant="subtitle1" component="p">
        {t('company.forAnyBusiness')}
      </Typography>
      <Typography variant="subtitle1" component="p">
        {t('company.ifYouHAveAnyQuestionsAboutOrder')}
      </Typography>

      <div className={`${classes.inputWrapper} ${classes.firstInput}`}>
        <p className={classes.errorMsg}>
          {fullNameErrorMsg && t(`dashboard.${fullNameErrorMsg}`)}
        </p>
        <Input
          placeholder={t('shared.fullName')}
          onChange={event => {
            setFullName(event.target.value)
            clearValidationMessages()
          }}
        />
      </div>
      <div className={classes.inputWrapper}>
        <p className={classes.errorMsg}>
          {emailErrorMsg && t(`messages.${emailErrorMsg}`)}
        </p>
        <Input
          type="email"
          placeholder={t('shared.emailAddress')}
          onChange={event => {
            setEmail(event.target.value)
            clearValidationMessages()
          }}
        />
      </div>
      <div className={classes.inputWrapper}>
        <p className={classes.errorMsg}>
          {typeErrorMsg && t(`messages.${typeErrorMsg}`)}
        </p>
        <select
          className={selectClasses}
          onChange={e => {
            setOptionSelected(e.target.value)
            clearValidationMessages()
          }}
        >
          <option className={classes.placeholderOption} value={''}>
            {t('company.selectEnquiryType')}
          </option>
          <option className={classes.option} value={`${t('company.general')}`}>
            {t('company.general')}
          </option>
          <option className={classes.option} value={`${t('company.press')}`}>
            {t('company.press')}
          </option>
          <option
            className={classes.option}
            value={`${t('company.technical')}`}
          >
            {t('company.technical')}
          </option>
        </select>
      </div>
      <div className={classes.inputWrapper}>
        <p className={classes.errorMsg}>
          {messageErrorMsg && t(`messages.${messageErrorMsg}`)}
        </p>
        <textarea
          className={classes.textArea}
          rows="8"
          placeholder={t('company.enterMessage')}
          onChange={event => {
            setMessage(event.target.value)
            clearValidationMessages()
          }}
        />
      </div>
      <div className={classes.buttonContainer}>
        <OutlinedButton
          label={t('company.send')}
          font={FONTS.Graphik.GraphikMedium}
          onClick={handleSubmitButtonClick}
        />
        {failErrorMsg && <p className={classes.errorMsg}>{failErrorMsg}</p>}
        <p className={classes.successMsg}>
          {successMsg && t(`company.${successMsg}`)}
        </p>
      </div>
    </section>
  )
}

export default withTranslation()(ContactUsTab)
